var term_data_basic_zh = `
欢迎你（即「用户」）使用我们提供的服务，本声明的发布者为Cigam Tech

请你仔细阅读、充分理解以下条款，你一旦登录服务，即视为你已经完全同意本协议各项内容，包括我们对使用协议所做的任何修改。如果你对本协议的任何条款表示异议，你可以选择终止登录（或停止使用服务）。

<strong>1. 接受条款</strong>

1.1 我们提供的服务根据本协议的条款为用户提供服务；用户一旦登录成功，无论是进入我们提供的服务，还是在我们提供的服务上发布任何内容，均意味着用户完全接受本协议项下的全部条款。

1.2 用户使用我们提供的单项服务时，应遵守与该服务相关的指引和规则。所有的指引和规则，均构成本使用协议的一部分。

1.3 用户直接或通过各类方式（如 RSS 源和站外 API 引用等）间接使用我们提供的服务和数据的行为，都将被视作已无条件接受本协议全部内容。

1.4 若用户对本协议的任何条款存在异议，请停止使用我们所提供的全部服务。

1.5 用户应遵守本协议各项条款，合法合理使用我们提供的服务；否则，我们有权依据本协议终止为用户提供服务。

1.6 我们保留在任何时候收回用户所使用账号的权利。

<strong>2. 使用规则</strong>

2.1 用户须对在我们提供的服务的登录信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用帐号导致其他用户误认。

2.2 我们提供的服务是一个信息分享、传播及获取的平台，用户通过我们提供的服务发表的信息为公开的信息，其他第三方均可以通过我们提供的服务获取用户发表的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在我们提供的服务上进行发表。

2.3 用户承诺不得以任何方式利用我们提供的服务直接或间接从事违反中国法律以及社会公德的行为，我们提供的服务有权对违反上述承诺的内容予以删除。

2.4 我们提供的服务仅供用户个人用于非商业用途。如果要销售或者与我们提供的服务有关或派生的任何资料、服务或软件，则必须得到我们的允许。如果用户对我们提供的服务有任何意见，或有如何改进的建议，可向我们提出。请注意，如果这样做，还会授予我们和第三方在提供的服务（或第三方软件）中无偿使用和加入用户的建议或意见的权利。

2.5 用户不得利用我们提供的服务制作、上传、复制、发布、传播或者转载如下内容：

&nbsp&nbsp a. 反对宪法所确定的基本原则的；

&nbsp&nbsp b. 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；

&nbsp&nbsp c. 损害国家荣誉和利益的；

&nbsp&nbsp d. 煽动民族仇恨、民族歧视，破坏民族团结的；

&nbsp&nbsp e. 侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；

&nbsp&nbsp f. 破坏国家宗教政策，宣扬邪教和封建迷信的；

&nbsp&nbsp g. 散布谣言，扰乱社会秩序，破坏社会稳定的；

&nbsp&nbsp h. 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；

&nbsp&nbsp i. 侮辱或者诽谤他人，侵害他人合法权益的；

&nbsp&nbsp j. 含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其他道德上令人反感的内容；

&nbsp&nbsp k. 含有中国法律、法规、（部门）规章以及任何具有法律效力之规范所限制或禁止的其他内容的；

&nbsp&nbsp l. 含有我们提供的服务认为不适合在我们提供的服务展示的内容。

<strong>3. 知识产权</strong>

我们提供的服务是一个信息获取、分享及传播的平台，我们尊重和鼓励用户创作的内容，认识到保护知识产权对我们提供的服务生存与发展的重要性，承诺将保护知识产权作为我们提供的服务运营的基本原则之一。

3.1 用户在我们提供的服务上发表的全部原创内容（包括但不仅限于讨论和评论），著作权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到我们提供的服务的同意。

3.2 第三方若出于非商业目的，将用户在我们提供的服务上发表的原创内容转载在我们提供的服务之外的地方，应当在作品的正文开头的显著位置注明原作者姓名（或原作者在我们提供的服务上使用的帐号名称），给出我们提供的服务原始链接，注明「发表于我们提供的服务」，并不得对作品进行修改演绎。若需要对作品进行修改，或用于商业目的，第三方应当联系用户获得单独授权，按照用户规定的方式使用该内容。

3.3 在我们提供的服务上传或发表的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权的异议，我们提供的服务有权根据实际情况删除相关的内容，且有权追究用户的法律责任。给我们提供的服务或任何第三方造成损失的，用户应负责全额赔偿。

3.4 如果任何第三方侵犯了我们提供的服务用户相关的权利，用户同意授权我们提供的服务或其指定的代理人代表我们提供的服务自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉，或谈判和解，并且用户同意在我们提供的服务认为必要的情况下参与共同维权。

3.5 我们提供的服务有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及我们提供的服务指导原则对侵权信息进行处理。在此处添加文本段落

<strong>4. 个人隐私</strong>

4.1 除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，我们提供的服务保证不对外公开或向第三方透露用户个人隐私信息，或用户在使用服务时存储的非公开内容。同时，为了运营和改善我们提供的技术与服务，我们提供的服务将可能会自行收集使用或向第三方提供用户的非个人隐私信息，这将有助于我们向用户提供更好的用户体验和服务质量。

<strong>5. 免责申明</strong>

5.1 我们提供的服务不能对用户发布的内容或评论的安全性、正确性进行保证。

5.2 用户在我们提供的服务发表的内容仅表明其个人的立场和观点，并不代表我们提供的服务的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。我们提供的服务不承担任何法律及连带责任。

5.3 我们提供的服务不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作保证。

5.4 对于因不可抗力或我们提供的服务不能控制的原因造成的网络服务中断或其它缺陷，我们提供的服务不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。

<strong>6. 协议修改</strong>

6.1 根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，我们提供的服务有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，我们提供的服务将会在网站上公布修改之后的协议内容，该公布行为视为我们提供的服务已经通知用户修改内容。我们提供的服务也可采用站内消息的传送方式，提示用户协议条款的修改、服务变更、或其它重要事项。

6.2 如果不同意我们提供的服务对本协议相关条款所做的修改，用户有权并应当停止使用我们提供的服务。如果用户继续使用我们提供的服务，则视为用户接受我们提供的服务对本协议相关条款所做的修改。

<strong>7. 法律</strong>

7.1 上述条款将受中华人民共和国法律的约束并依据其解释。如出现纠纷，用户和本公司一致同意将纠纷交由当地人民法院管辖。如用户对本服务条款内容有任何疑问，可发邮件至<a href="mailto:cigamtech@outlook.com">cigamtech@outlook.com</a>或访问<a href="https://www.cigamtech.com" target="_blank">www.cigamtech.com</a>获取相关信息。
`
var term_data_basic_zh = term_data_basic_zh.replace(/\r\n/g,"<br>")
var term_data_basic_zh = term_data_basic_zh.replace(/\n/g,"<br>");

export default term_data_basic_zh